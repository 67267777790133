import { CardProps } from '../components/Card';

export const experiences: CardProps[] = [
  {
    from: new Date(2022, 9, 1),
    to: new Date(),
    title: 'Frontend-Developer',
    location: 'c-Com GmbH',
    description: '',
    tags: [
      'TypeScript',
      'Angular',
      'Git',
      'Web Development',
      'JavaScript',
      'Node.js',
      'Jasmine',
    ],
  },
  {
    from: new Date(2022, 3, 1),
    to: new Date(2022, 8, 1),
    title: 'Bachelor Thesis',
    location: 'c-Com GmbH',
    description: '',
    tags: [
      'TypeScript',
      'Angular',
      'Data Visualisation',
      'Three.js',
      'JavaScript',
      'Node.js',
    ],
  },
  {
    from: new Date(2021, 3, 1),
    to: new Date(2022, 3, 1),
    title: 'Working Student',
    location: 'c-Com GmbH',
    description: '',
    tags: [
      'TypeScript',
      'Angular',
      'Git',
      'HTML',
      'JavaScript',
      'Node.js',
      'Jasmine',
      'JavaScript',
    ],
  },
  {
    from: new Date(2020, 9, 1),
    to: new Date(2021, 3, 1),
    title: 'Internship',
    location: 'c-Com GmbH',
    description: '',
    tags: ['TypeScript', 'Angular', 'Git', 'HTML', 'SCSS'],
  },
  {
    from: new Date(2018, 9, 1),
    to: new Date(2022, 8, 1),
    title: 'University',
    location: 'Aalen University',
    description: '',
    tags: [
      'Backend-Development',
      'Frontend-Development',
      'Git',
      'React',
      'React Native',
      'Flutter',
      'MongoDB',
      'PostgreSQL',
      'Java',
      'Prisma',
      'Redis',
      'Software Engineering',
    ],
  },
];
